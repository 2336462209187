import { Button } from "@progress/kendo-react-buttons";

import {
	chevronDownIcon,
	chevronUpIcon,
	dollarIcon,
	eyeIcon,
	pencilIcon,
} from "@progress/kendo-svg-icons";
import { sumBy } from "es-toolkit";
import { truckIcon } from "../../../libs/common/models/src/lib/constants/icon.constants";
import { DetailsCard } from "../../DetailsCard";
import { DetailsDisplayLocation } from "../../DetailsDisplayLocation";
import { DetailsInformationCard } from "../../DetailsInformationCard";
import type { LoadDetailsResponse } from "../../api/JobApi";
import { DFlex } from "../../display/DFlex";
import { DGrid } from "../../display/DGrid";
import { toCurrency } from "../../helpers";

type LoadDetailsInformationProps = {
	data: LoadDetailsResponse;
	onEditClick: () => void;
	onViewDocumentsClick: () => void;
};
export const LoadDetailsInformation = ({
	data,
	onEditClick,
	onViewDocumentsClick,
}: LoadDetailsInformationProps) => (
	<DetailsCard
		title="Load Information"
		buttons={
			<>
				<Button svgIcon={eyeIcon} onClick={onViewDocumentsClick}>
					Documents{" "}
					{data.documents.length ? `(${data.documents.length})` : undefined}
				</Button>
				<Button svgIcon={pencilIcon} themeColor="primary" onClick={onEditClick}>
					Edit Info
				</Button>
			</>
		}
	>
		<DFlex gap="8px" column>
			<DGrid columns="repeat(2, 1fr)" gap="8px">
				<DetailsInformationCard
					icon={eyeIcon}
					title="Operator"
					value={data.assignedTo}
				/>
				<DetailsInformationCard
					icon={truckIcon}
					title="Truck Weight"
					value={`${sumBy(data.goods, (g) => g.weight ?? 0)}kg`}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="Shared Cost"
					value={
						data.sharedCost
							? toCurrency(data.sharedCost, data.currencyCode)
							: "-"
					}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="Profit"
					value={`${(data.profit ?? 0).toFixed(2)}%`}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="Total Cost"
					value={data.cost ? toCurrency(data.cost, data.currencyCode) : "-"}
				/>
				<DetailsInformationCard
					icon={dollarIcon}
					title="Revenue"
					value={
						data.revenue ? toCurrency(data.revenue, data.currencyCode) : "-"
					}
				/>
			</DGrid>
			<DGrid columns="repeat(2, 1fr)" gap="8px">
				<DetailsInformationCard
					icon={chevronUpIcon}
					title="Start Location"
					value={<DetailsDisplayLocation {...data.startLocation} />}
				/>
				<DetailsInformationCard
					icon={chevronDownIcon}
					title="End Location"
					value={<DetailsDisplayLocation {...data.endLocation} />}
				/>
			</DGrid>
		</DFlex>
	</DetailsCard>
);
